import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Cycles from "./pages/kegs/Cycles";
import Invoices from "./pages/statements/Invoices";
import InvoiceItems from "./pages/statements/InvoiceItems";
import Dashboard from "./pages/starlight/Dashboard";
import Journey from "./pages/starlight/Journey";
import Kegs from "./pages/starlight/Kegs";
// import CycleList from "./resources/cycles/CycleList";

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route
        path="/kegs/cycles"
        children={<Cycles />}
    />,
    <Route
        path="/statements/invoices/:uuid"
        children={<InvoiceItems />}
    />,
    <Route
        path="/statements/invoices"
        children={<Invoices />}
    />,
    <Route
        path="/starlight/dashboard"
        children={<Dashboard />}
    />,
    <Route
        path="/starlight/kegs/:cycleUuid/journey"
        children={<Journey />}
    />,
    <Route
        path="/starlight/kegs/:kegUuid/:turnUuid/journey"
        children={<Journey />}
    />,
    <Route
        path="/starlight/kegs/list/:locationUuid"
        children={<Kegs />}
    />,
    <Route
        path="/starlight/kegs/list"
        children={<Kegs />}
    />
];
