import React from 'react';
import { forwardRef } from 'react';
import { Pagination, Notification } from 'react-admin';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {useQuery} from "react-admin";
import {Box, Chip, CircularProgress, Container} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CancelIcon from "@material-ui/icons/Cancel";
import SuccessIcon from "@material-ui/icons/CheckCircle";

export const GetMarket = ({marketId}) => {
    let marketName = '';

    switch (marketId) {
        case 1:
            marketName = 'AU';
            break;
        case 2:
            marketName = 'NZ';
            break;
        case 3:
            marketName = 'UK';
            break;
        case 4:
            marketName = 'US';
            break;
        default:
            marketName = 'Global';
    }
    return marketName;
}

export const getMarketColorCode = (market) => {
    let colorCode = '#525558';
    switch (market) {
        case "1":
            colorCode = '#496901';
            break;
        case "2":
            colorCode = '#607080';
            break;
        case "3":
            colorCode = '#7E0819';
            break;
        case "4":
            colorCode = '#201d56';
            break;
        default:
            colorCode = '#525558';
            break;
    }

    return colorCode;
}

export const GetCountry = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'countries',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};

export const GetOrganisation = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'organisations',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};

export const GetLocation = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'locations',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};

export const GetSku = ({uuid}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'skus',
        payload: { id: uuid }
    });

    if (loading) return <CircularProgress size={20}/>;
    if (error) return <ErrorIcon style={{color: 'red'}} />;
    if (!data) return null;

    return data.name
};


export const defaultTableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export function formatFeeDisplay(fee) {
    if(fee) {
        return parseFloat(fee).toFixed(2);
    } else {
        return '-';
    }
}

export function getCountryUuid() {
    let countryId = localStorage.getItem('cId');

    switch (countryId) {
        case ("1" || 1):
            return '52aa17f5-c15f-4ab1-8ee6-8ca7ab481136';
        case ("2" || 2):
            return '81359d1d-c771-428f-8d34-ac9a853c99fe';
        case ("3" || 3):
            return '96b787e1-581a-4dba-95a5-35f1080ffa76';
        case ("4" || 4):
            return 'a8e590c3-4853-4780-9a9e-b42ce8fac73f';
        default:
            return null;
    }

}

export function stopAtRoleDisplay(stopAtRole) {
    switch (stopAtRole) {
        case 1:
            return "ROLE_WAREHOUSE";
        case 2:
            return "ROLE_PRODUCER";
        case 3:
            return "ROLE_DISTRIBUTOR";
        case 4:
            return "ROLE_VENUE";
        case 5:
            return "ROLE_COLLECTOR";
        case 6:
            return "ROLE_CONTRACT_BREWER";
        default:
            return "";
    }
}

export const ChargeFeeTypes = [
    { id: 1, name: 'Issue Fee' },
    { id: 2, name: 'Daily Rental Fee' },
    { id: 3, name: 'Timed Fee' },
    { id: 4, name: 'Cleaning Fee' },
    { id: 5, name: 'Freight Capital Metro Fee' },
    { id: 6, name: 'Inter-Country Fee' },
    { id: 7, name: 'Taproom Venue Fee' },
    { id: 8, name: 'Taproom Distributor Fee' },
    { id: 9, name: 'Freight Regional Metro Fee' },
    { id: 10, name: 'Freight Regional Fee' },
    { id: 11, name: 'Manual Fee' },
    { id: 12, name: 'Freight Super Regional Fee' },
    { id: 13, name: 'Collection Regional Fee' },
    { id: 14, name: 'Collection Super Regional Fee' }
];

export function unauthorisedAccountingAccess() {
    if( !(localStorage.getItem('role').indexOf('ROLE_ACCOUNTING') > -1) ) {
        return true;
    } else {
        return false;
    }
}
export const UnauthorisedAccountingAccess = () => {
    return (
        <Container style={{ maxWidth: '1230px', backgroundColor: '#e7e6e7', borderColor: '#000' }} >
            <Box p={2} component="div" style={{ backgroundColor: '#fff', height: '100%', marginTop: '-7px', marginLeft: '-3px', marginRight: '3px' }} >
                <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                    <AlertTitle variant={'h6'}> Unauthorised </AlertTitle>
                    You are not allowed to access this page.
                </Alert>
            </Box>
        </Container>
    )
}

export function isForFinancialNavigation() {
    if (window.location.href.indexOf('/billing') > -1) {
        return true;
    } else {
        return false;
    }
}

export function isForOrderFeeOverrideNavigation() {
    if (window.location.href.indexOf('/orders/edit') > -1) {
        return true;
    } else {
        return false;
    }
}

export const highlightCurrentPsaDiscount = (rentalCount, lowerBound, upperBound) => {
    if (!rentalCount) return false;
    if( (rentalCount >= lowerBound) && (rentalCount <= upperBound) ) {
        return true;
    } else {
        return false;
    }
}

export const ProcessStatusDisplay = ({processStatusText}) => {
    let avatar = <MoreHorizIcon style={{backgroundColor: '#31708f'}} />;
    let style = {paddingLeft: '6px', backgroundColor: '#31708f'};
    let showLoading = false;

    if (processStatusText.toLowerCase().indexOf('canceled') > -1 || processStatusText.toLowerCase().indexOf('terminated') > -1) {
        avatar = <CancelIcon style={{backgroundColor: '#e5b800'}} />;
        style = {paddingLeft: '6px', backgroundColor: '#e5b800'};
    } else if (processStatusText.toLowerCase().indexOf('failed') > -1) {
        avatar = <ErrorIcon style={{backgroundColor: '#a90018'}}  />;
        style = {paddingLeft: '6px', backgroundColor: '#a90018'};
    } else if (processStatusText.toLowerCase().indexOf('completed') > -1) {
        avatar = <SuccessIcon style={{backgroundColor: '#5cab00'}}/>;
        style = {paddingLeft: '6px', backgroundColor: '#5cab00'};
    } else {
        showLoading = true;
    }

    return (
        <React.Fragment>
            <Chip label={processStatusText} color={'secondary'} avatar={avatar} style={style} size="small" />
            { showLoading && <CircularProgress size={10} style={{marginTop: '10px', marginLeft: '5px'}} /> }
        </React.Fragment>
    )
}

export const stopAtRoleChoices = [
        { id: 'ROLE_WAREHOUSE', name: 'Warehouse' },
        { id: 'ROLE_PRODUCER', name: 'Producer' },
        { id: 'ROLE_DISTRIBUTOR', name: 'Distributor' },
        { id: 'ROLE_VENUE', name: 'Venue' }
    ]


export const setCurrentFilters = (props, filters) => {
    // Reset filters first
    for (var i = 0; i < filters.length; i++) {
        localStorage.removeItem(filters[i]+'_Filter');
    }
    // Set current filters
    for (const i in props.filterValues) {
        if(props.filterValues[i]){
            localStorage.setItem(`${i}_Filter`, props.filterValues[i]);
        }
    }
}

export const applyCurrentFilter = (defaultFilterValues, filters) => {

    for (const i in filters) {
        let key = filters[i];
        if(localStorage.getItem(key+'_Filter')){
            defaultFilterValues = {...defaultFilterValues, [key]: localStorage.getItem(key+'_Filter')};
        }
    }

    return defaultFilterValues;
}

export const CustomPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

export function getLocationRoleNames(locationDetails) {
    let roles = [];

    if(locationDetails.isWarehouse){
        roles.push('Warehouse');
    }
    if(locationDetails.isProducer){
        roles.push('Producer');
    }
    if(locationDetails.isDistributor){
        roles.push('Distributor');
    }
    if(locationDetails.isVenue){
        roles.push('Venue');
    }

    return roles;
}

export const MarketGeoCoordinates = [
    { id: 0, marketCode: 'Global', latitude: 0, longitude: 0 },
    { id: 1, marketCode: 'AU', latitude: -33.865143, longitude: 151.209900 },
    { id: 2, marketCode: 'NZ', latitude: -43.3745, longitude: 172.4663 },
    { id: 3, marketCode: 'UK', latitude: 55.378051, longitude: -3.435973 },
    { id: 4, marketCode: 'US', latitude: 37.09024, longitude: -95.712891 },
];

export function checkMystarStarlightAccess() {
    if( (localStorage.getItem('isMytarStarlightEnabled') === 'true') ) {
        return true;
    } else {
        return false;
    }
}
export const UnauthorisedMystarStarlightAccess = () => {
    return (
        <Container style={{ maxWidth: '1356px'}} >
            <Box p={2} component="div" style={{height: '100%', marginTop: '-7px', marginLeft: '-3px', marginRight: '3px' }} >
                <Alert severity="error" style={{display: 'flex', width: '95%'}}>
                    <AlertTitle variant={'h6'}> Unauthorised </AlertTitle>
                    You are not allowed to access this page.
                </Alert>
            </Box>
        </Container>
    )
}

export const downloadProgress = (currentPage, total, perPage) => {
    let percentage = currentPage/(total/perPage) * 100;
    if(percentage >= 100) {
        percentage = 100;
    }
    return Math.floor((percentage).toFixed(0));
}


export const CustomNotification = props => <Notification {...props} autoHideDuration={20000}
                                                         ContentProps={{ style: { backgroundColor: '#000', borderColor: '#000'} }} />;
