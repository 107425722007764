import React, {useEffect, useState} from "react";
import {Container, Grid, LinearProgress, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: '200px'
    },
}));

const AverageTurns = ({avgDaysData, ...props}) => {
    const classes = useStyles();

    return (
        <React.Fragment>

            <Box>
                <Paper square={true}>
                    <Box m={2}>
                        <Box>
                            <Box m={1}><Typography variant={'h5'}>Average Cycle Time</Typography></Box>
                            <Box m={1}>
                                { !avgDaysData && <LinearProgress /> }
                                { avgDaysData &&
                                    <Typography variant={'h4'}>{ avgDaysData.cycleAvgNoOfDays ? `${Math.floor(parseFloat(avgDaysData.cycleAvgNoOfDays))} Days` : '-'}  </Typography>

                                }
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Box>
                <Paper square={true}>
                    <Box m={2}>
                        <Box>
                            <Box m={1}><Typography variant={'h5'}>Average Time at Producer</Typography></Box>
                            { !avgDaysData && <LinearProgress /> }
                            {
                                avgDaysData && <Box m={1}><Typography variant={'h4'}> { avgDaysData.producerAvgNoOfDays ? `${Math.floor(parseFloat(avgDaysData.producerAvgNoOfDays))} Days` : '-'} </Typography></Box>
                            }
                            {
                                avgDaysData &&
                                <Box m={1}><Typography variant={'h6'}>Number of cycle analysed: {avgDaysData.noOfTurn}</Typography></Box>
                            }
                        </Box>
                    </Box>
                </Paper>
            </Box>



        </React.Fragment>
    )
}

export default AverageTurns;
