// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const KegsEventExport = (records) => {
    const dataForExport = records.map(record => {
        const {
            id,	date, dateTime,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'kegEventTime', 'kegEventName', 'kegEventValue'
        ], // order fields in the export
        rename:[
            'Event Time', 'Event', 'Value',
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'KegsEvents_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default KegsEventExport;
