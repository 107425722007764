import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    shape: {
        backgroundColor: '#1f2124',
        width: 125, height: 125,
    },
    shapeCircle: {
        borderRadius: '50%',
    },
    eventValue: {
        color: '#fff',
        fontSize: '40px'
    }
}));

export default function EventBadge({eventName, eventValue}) {
    const classes = useStyles();

    const content = <React.Fragment>
        <div className={clsx(classes.shape, classes.shapeCircle)}
             // style={{width: 50, height: 50}}
        >
            <div style={{color: '#fff', fontSize: 'larger', margin: 0, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                <div style={{display: "block", textAlign: 'center'}}><span className={classes.eventValue}>{eventValue}</span></div>
                <div style={{display: "block", textAlign: 'center'}}><span style={{fontSize: 'smaller'}}>days</span></div>
            </div>
        </div>
    </React.Fragment>;


    return (
        <div className={classes.root}>
            <Badge badgeContent={0}>
                {content}
            </Badge>
        </div>
    );
}