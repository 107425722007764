import React, {Fragment} from "react";
import {useQuery} from "react-admin";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import GetIcon from '@material-ui/icons/GetApp';

import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles(theme => ({
    button: {borderRadius: '0px'}
}));

export const InvoicePDFButton = ( {record} ) => {

    const classes = useStyles();
    const theme = useTheme();
    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'invoices',
        payload: {
            pagination: { page: 1 , perPage: 5 }, sort:{ field: 'createdAt', order: 'DESC' }, filter: {draftInvoiceUuid: record.uuid}
        }
    });

    if (loading) return <CircularProgress />
    // if (error) return <Error />;
    if (!data) return null;
    if (!data[0]) return null;

    return (
        <Fragment>
            <Link href={data[0].invoiceUrl} target="_blank" rel="noreferrer">
                <Button variant={'none'} className={classes.button} style={{backgroundColor: theme.palette.primary.main , color: theme.palette.secondary.contrastText }}>
                    <GetIcon /> Download
                </Button>
            </Link>
        </Fragment>
    )
};
