import React, {Fragment} from "react";
import styled, { keyframes } from 'styled-components';
import {bounceIn} from 'react-animations';
import {makeStyles} from "@material-ui/core/styles";
import AdjustIcon from '@material-ui/icons/Adjust';

const loadingAnimationBounceIn = keyframes`${bounceIn}`;
const LoadingWrapperBounceIn = styled.div`
  animation: 1.5s ${loadingAnimationBounceIn} infinite;
`;
const useStyles = makeStyles( theme => ({
    root: {
        position: 'absolute',
        left: '-10px',
        top: '-14px',
        zIndex: -1
    },
    circle: {
        opacity: 0.25,
        filter: 'invert(1)',
        fontSize: '58px'
    },
}));

const SignalAnimation = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LoadingWrapperBounceIn>
                <AdjustIcon className={classes.circle} />
            </LoadingWrapperBounceIn>
        </div>
    )
}

export default SignalAnimation;
