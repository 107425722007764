import React, { useState, useRef, useEffect } from "react";
import ReactMapGL, { Marker, FlyToInterpolator, Popup, NavigationControl, GeolocateControl, ScaleControl } from "react-map-gl";
import useSupercluster from "use-supercluster";
import KegstarKegWhiteIcon from "../../assets/images/starlight-kegs-icon.png";
import Box from "@material-ui/core/Box";
import axios from 'axios';
import Typography from "@material-ui/core/Typography";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {CircularProgress, Link} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import PolylineOverlay from "./PolylineOverlay";
import {MarketGeoCoordinates} from "../../utilities/Common";
import SignalAnimation from "../../components/loaders/SignalAnimation";
const useStyles = makeStyles(theme => ({
    icon: {
        fill: '#fff',
    },
}));


export default function KegJourneyMap({cycleUuid}) {
    const classes = useStyles();

    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: '#ccc',
            zIndex: 1,
            color: '#fff',
            width: 50,
            height: 50,
            display: 'flex',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        active: {
            backgroundImage:
                'linear-gradient( 136deg, rgb(46 46 46) 0%, rgb(31 33 36) 50%, rgb(69 69 69) 100%)',
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        },
        completed: {
            backgroundImage:
                'linear-gradient( 136deg, rgb(46 46 46) 0%, rgb(31 33 36) 50%, rgb(69 69 69) 100%)',
        },
    });

    function ColorlibStepIcon(props, step) {
        const classes = useColorlibStepIconStyles();

        const { active, completed } = props;
        let index = 0;
        const icons = {
            0: '?',
            1: 'W',
            2: 'P',
            3: 'D',
            4: 'V',
            5: 'C',
        };
        if(steps[step].includes('Warehouse')){
            index = 1;
        } else if(steps[step].includes('Producer')) {
            index = 2;
        } else if(steps[step].includes('Distributor')) {
            index = 3;
        } else if(steps[step].includes('Venue')) {
            index = 4;
        } else if(steps[step].includes('Collector')) {
            index = 5;
        }
        return (
            <React.Fragment>
               <div style={{display: 'flex',
                   justifyContent: 'center',
                   alignItems: 'center',
                   zIndex: 1}}>
                   { (active || completed) && step != 0 && <ChevronRightIcon style={{fontSize: '2.25rem', color: '#90be51',
                       marginLeft: '-21px', marginRight: '-12px', marginTop: '-3px', top: '6px'}} /> }
                   <div onClick={()=> handleStepChange(step)}
                        className={clsx(classes.root, {
                            [classes.active]: active,
                            [classes.completed]: completed,
                        })} style={{cursor: 'pointer', marginLeft: 0, paddingLeft: 0}}
                   >
                       {/*{icons[String(props.icon)]}*/}
                       {icons[index]}
                   </div>
               </div>
            </React.Fragment>

        );
    }

    ColorlibStepIcon.propTypes = {
        /**
         * Whether this step is active.
         */
        active: PropTypes.bool,
        /**
         * Mark the step as completed. Is passed to child components.
         */
        completed: PropTypes.bool,
        /**
         * The label displayed in the step icon.
         */
        icon: PropTypes.node,
    };

    function getSteps(locationDetails) {

        let steps = [];

        if(locationDetails == 1){
            steps.push('Warehouse');
        }
        if(locationDetails == 2){
            steps.push('Producer');
        }
        if(locationDetails == 3){
            steps.push('Distributor');
        }
        if(locationDetails == 4){
            steps.push('Venue');
        }
        if(locationDetails == 5){
            steps.push('Collector');
        }

        return steps;
    }

    function setupSteps(items) {
        let ssteps = [];
        for (const i in items) {
            if(items[i].locationRoles){
                ssteps.push(items[i].locationRoles.join('/'));
            }
        }
        setSteps(ssteps);
        setActiveStep(ssteps.length - 1);
        // return steps;
    }

    const [viewport, setViewport] = useState({
        latitude: MarketGeoCoordinates[localStorage.getItem('mId')].latitude,
        longitude: MarketGeoCoordinates[localStorage.getItem('mId')].longitude,
        width: "100%",
        height: "100%",
        zoom: 10
    });
    const mapRef = useRef();

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedSubIndex, setSelectedSubIndex] = useState(null);
    const [eventType, setEventType] = useState(null);
    const [results, setResults] = useState([]);
    const [points, setPoints] = useState([]);
    const [linearPoints, setLinearPoints] = useState([]);
    const [subLinearPoints, setSubLinearPoints] = useState([]);
    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/kegs/${cycleUuid}/journey`);
    const [kegMapJourneyUrl, setKegMapJourneyUrl] = useState(baseUrl);

    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = useState([]);
    const handleStepChange = (step) => {
        setActiveStep(step);
        goToLocationPoint(points[step].geometry.coordinates, step);
    }

    const ColorlibConnector = withStyles({
        alternativeLabel: {
            top: 22,
        },
        active: {
            '& $line': {
                backgroundImage:
                    'linear-gradient( 95deg,#90be51 0%, #90be51 50%, #90be51 100%)',
            },
        },
        completed: {
            '& $line': {
                backgroundImage:
                    'linear-gradient( 95deg,#90be51 0%, #90be51 50%, #90be51 100%)',
            },
        },
        line: {
            height: 3,
            border: 0,
            backgroundColor: '#fff',
            borderRadius: 1,
        },
    })(StepConnector);

    useEffect(() => {
        (async () => {
            try {
                const result = await axios.get(kegMapJourneyUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                // console.log('kegDeviceLocation', result.data.kegDeviceLocation);
                // console.log('kegMovementLocation', result.data.kegMovementLocation);
                const data = result.data;
                // const results = data ? data.slice(0, 3000) : [];
                setResults(data);

                const newPoints = data.map(record => ({
                    type: "Feature",
                    properties: { cluster: false,
                        id: record.id,
                        locationName: (record.locationName) ? record.locationName : null,
                        locationRoles: record.locationDetails ? getSteps(record.locationDetails) : null,
                        organisationName: record.organisationName,
                        kegSerialNumber: record.kegSerialNumber,
                        kegFilledState: record.kegFilledState,
                        kegArrivedAt: record.kegArrivedAt,
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            parseFloat(record.locationLongitude),
                            parseFloat(record.locationLatitude)
                        ]
                    }
                }));
                // console.log('newPoints', newPoints);
                setPoints(newPoints);

                const newSteps = data.map(record => ({
                    locationRoles: record.locationRole ? getSteps(record.locationRole) : null,
                    longitude: parseFloat(record.locationLongitude),
                    latitude: parseFloat(record.locationLatitude),
                }));
                // console.log('newSteps', newSteps);
                setupSteps(newSteps);

                let newLinearPoints1 = result.data.filter(v => (v.locationLongitude !== null &&
                    v.locationLatitude !== null) ).map(record =>
                    [ parseFloat(record.locationLongitude), parseFloat(record.locationLatitude) ]
                );

                let newLinearPoints2 = result.data.filter(v => (v.locationLongitude !== null &&
                    v.locationLatitude !== null) ).map(record =>
                    [ parseFloat(record.locationLongitude), parseFloat(record.locationLatitude), (record.locationName) ? record.locationName : null ]
                );

                 // const newLinearPoints = newLinearPoints1.concat(newLinearPoints2);
                // console.log(newLinearPoints1);
                // console.log(newLinearPoints2);
                let newLinearPoints = newLinearPoints1.concat(newLinearPoints2);
                setLinearPoints(newLinearPoints);
                setSubLinearPoints(newLinearPoints2);

                setViewport({
                    latitude: parseFloat(data[newSteps.length - 1].locationLatitude),
                    longitude: parseFloat(data[newSteps.length - 1].locationLongitude),
                    width: "100%",
                    height: "650px",
                    zoom: 4.5
                });
                setTimeout(function(){
                    setSelectedIndex(newSteps.length - 1);
                }, 3000);

            } catch (error) {
                console.log('ERROR', error);
            }

        })();
    }, [kegMapJourneyUrl]);

    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    const CustomPopup = ({index, marker, closePopup}) => {

        if (!marker || marker.length <= 0) {
            return null;
        }
        let [longitude, latitude] = marker.geometry.coordinates;
        if(!marker.properties.locationName){
            return null;
        }
        return (
            <Popup
                latitude={latitude}
                longitude={longitude}
                onClose={closePopup}
                closeButton={true}
                closeOnClick={false}
                offsetLeft={18}
            >
                <Typography variant={'h6'}>{ marker.properties.locationName ? marker.properties.locationName : 'Unknown Location'}</Typography>
                <Typography variant={'body1'}>{ marker.properties.locationRoles ? <span>Location Role: <strong>{ marker.properties.locationRoles.join('/') }</strong></span> : <CircularProgress size={'small'} /> } </Typography>
                {/*<Typography variant={'body1'}>Status: <strong>{ (marker.properties.kegFilledState) ? 'Full' : 'Empty'}</strong> </Typography>*/}
                {/*<Typography variant={'body1'}>Ave. Temp: {marker.properties.kegAVGTemperature ? <strong>{ marker.properties.kegAVGTemperature } &deg;C</strong>  : '?' } </Typography>*/}
                <Typography variant={'body1'}>Arrived At: <strong>{ new Date(marker.properties.kegArrivedAt).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric', hour12: true }) }</strong> </Typography>
            </Popup>
        )};
    const CustomSubPopup = ({index, marker, closePopup}) => {

        if (!marker || marker.length <= 0) {
            return null;
        }
        let longitude = marker[0];
        let latitude = marker[1];
        let locationName = marker[2];

        return (
            <Popup
                latitude={latitude}
                longitude={longitude}
                onClose={closeSubPopup}
                closeButton={true}
                closeOnClick={false}
                offsetLeft={18}
            >
                <Typography variant={'subtitle1'}>{ locationName ? locationName : 'Unknown Location'}</Typography>
            </Popup>
        )};
    const CustomMarker = ({index, marker, openPopup}) => {
        return (
            <Marker
                key={`marker-${marker.kegSerialNumber}`}
                longitude={marker.longitude}
                latitude={marker.latitude}>
                <React.Fragment>
                    <SignalAnimation />
                    <button className="starlight-keg-marker" onClick={() => openPopup(index)}>
                        <img src={KegstarKegWhiteIcon} alt="Starlight Keg" />
                    </button>
                </React.Fragment>
            </Marker>
        )};

    const closePopup = () => {
        setSelectedIndex(null);
    };
    const closeSubPopup = () => {
        setSelectedSubIndex(null);
    };
    const openPopup = (index) => {
        setSelectedIndex(index);
    }
    const openSubPopup = (index) => {
        setSelectedSubIndex(index);
    }
    const remove = (index) => {
        setSelectedIndex(null);
    }
    const removeSub = (index) => {
        setSelectedSubIndex(null);
    }
    const goToLocationPoint = (coordinates, index) => {

        setViewport({
            ...viewport,
            longitude: coordinates[0],
            latitude: coordinates[1],
            transitionDuration: 1600,
            zoom: 16,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: t => t * (2 - t)
        } );
        //setTimeout(function(){ setSelectedIndex(index) }, 3000)
    };

    return (
        <div>

            <Box display="inline" style={{backgroundColor: '#1f2124'}}>
                { steps &&
                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{padding: 0, background: '#e7e6e7', borderColor: '#e7e6e7'}}>
                        {steps.map((label, index) => (
                            <Step key={index} >
                                <StepLabel StepIconComponent={(props)=>ColorlibStepIcon(props, index)}>
                                    <Typography variant={'subtitle2'}>{points[index].properties.locationName}</Typography>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                }
            </Box>

            <ReactMapGL
                {...viewport}
                maxZoom={120}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                onViewportChange={newViewport => {
                    setViewport({ ...newViewport });
                }}
                ref={mapRef}
                mapStyle={'mapbox://styles/kegstartech/cklub9v6l393j17r1kj5pe7wk'}
            >
                {/*{ subLinearPoints && <PolylineOverlay points={subLinearPoints} color={'#90be51'} offset={16} /> }*/}
                { linearPoints && <PolylineOverlay points={linearPoints} color={'#90be51'} offset={16} /> }

                { subLinearPoints.map((pingLocation, i) => {
                        return (
                            <Marker
                                key={`ping-loc-${i}`}
                                longitude={pingLocation[0]}
                                latitude={pingLocation[1]}>
                                <React.Fragment>
                                    {/*<button className="starlight-keg-marker" >*/}
                                    <RadioButtonCheckedIcon style={{ color: '#90be51', marginLeft: '5px', marginTop: '5px' }} onClick={() => openSubPopup(i)} />
                                    {/*</button>*/}
                                </React.Fragment>
                            </Marker>
                        )
                    })
                }

                {
                    clusters.map((cluster, index) => {

                        let [longitude, latitude] = cluster.geometry.coordinates;

                        const {
                            cluster: isCluster,
                            point_count: pointCount
                        } = cluster.properties;

                        if (isCluster) {
                            return (
                                <Marker
                                    key={`cluster-${cluster.id}`}
                                    latitude={latitude}
                                    longitude={longitude}
                                >
                                    <div
                                        className="cluster-marker"
                                        style={{
                                            width: `${10 + (pointCount / points.length) * 20}px`,
                                            height: `${10 + (pointCount / points.length) * 20}px`
                                        }}
                                        onClick={() => {
                                            const expansionZoom = Math.min(
                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                25
                                            );

                                            setViewport({
                                                ...viewport,
                                                latitude,
                                                longitude,
                                                zoom: expansionZoom,
                                                transitionInterpolator: new FlyToInterpolator({
                                                    speed: 2
                                                }),
                                                transitionDuration: "auto"
                                            });

                                        }}
                                    >
                                        {pointCount}
                                    </div>
                                </Marker>
                            );
                        }

                        return (
                            <React.Fragment>
                                <CustomMarker
                                    key={`marker-${cluster.properties.id}`}
                                    index={index}
                                    marker={{latitude: latitude, longitude: longitude}}
                                    openPopup={openPopup}
                                />

                            </React.Fragment>
                        );
                    })}
                {
                    selectedSubIndex !== null &&
                    <CustomSubPopup
                        index={selectedSubIndex}
                        marker={subLinearPoints[selectedSubIndex]}
                        closePopup={closeSubPopup}
                        remove={removeSub}
                    />
                }
                {
                    selectedIndex !== null &&
                    <CustomPopup
                        index={selectedIndex}
                        marker={clusters[selectedIndex]}
                        closePopup={closePopup}
                        remove={remove}
                    />
                }

                <NavigationControl style={{right: 10, top: 10}} />
                <ScaleControl style={{left: 10, top: 10}} />
                <GeolocateControl style={{left: 10, top: 30}} />
                {/*<FullscreenControl style={{left: 10, top: 60}} />*/}
            </ReactMapGL>

        </div>
    );
}

