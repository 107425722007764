import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import EventBadge from "./EventBadge";
import {Typography} from "@material-ui/core";
import TemperatureDefault from "../../assets/images/temperature.svg";
import TemperatureOkay from "../../assets/images/temperature-okay.svg";
import TemperatureCold from "../../assets/images/temperature-cold.svg";
import TemperatureWarm from "../../assets/images/temperature-warm.svg";

const useStyles = makeStyles((theme) => ({
    eventNameContainer: {
        textAlign: 'center'
    },
    eventName: {
        fontWeight: 'bold'
    },
    temperatureLabels: {
        color: '#fff',
        paddingLeft: '10px'
    },
    temperatureValue: {
        color: '#90be51'
    }
}));

const TypeLabel = ({type}) => {
    switch (type) {
        case 0:
            return <span>Too Cold <span style={{fontSize: '18px'}}>( &#60; 2 &deg;C )</span> </span>;
            break;
        case 1:
            return <span>Just Right <span style={{fontSize: '18px'}}>( 2-9 &deg;C )</span> </span>;
            break;
        case 2:
            return <span>Warm <span style={{fontSize: '18px'}}>( &#62; 9 &deg;C )</span> </span>;
            break;
    }
    return null;
}

const TemperatureSummary = ({temperatureData, ...props}) => {

    const classes = useStyles();
    let TemperatureIcon = TemperatureDefault;
    let color = '';
    switch (temperatureData.type) {
        case 0:
            TemperatureIcon = TemperatureCold;
            color = '#0288D1';
            break;
        case 1:
            TemperatureIcon = TemperatureOkay;
            color = '#689F38';
            break;
        case 2:
            TemperatureIcon = TemperatureWarm;
            color = '#D32F2F';
            break;
    }

    return (
        <React.Fragment>
            <Paper square={true} style={{backgroundColor: '#1f2124'}}>
                <Grid container spacing={1}>
                    <Grid item><img src={TemperatureIcon} alt={''} style={{width: '110px', padding: 0, margin: 0}} /></Grid>
                    <Grid item xs >
                        <Typography variant={'h6'} className={classes.temperatureLabels} style={{paddingTop: '5px', fontSize: '24px'}}>
                            <TypeLabel type={temperatureData.type} />
                        </Typography>
                        <Typography variant={'h6'} className={classes.temperatureLabels}> <span style={{color: color}}>{temperatureData.kegsAtDistributor}</span> kegs at Distributor </Typography>
                        <Typography variant={'h6'} className={classes.temperatureLabels}> <span style={{color: color}}>{temperatureData.kegsAtVenue}</span> kegs at Venue </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default TemperatureSummary;

