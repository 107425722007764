// @ts-nocheck
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Admin, Resource, DataProvider } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import dataProviderFactory from './dataProvider';

import cycles from './resources/cycles';
import invoices from './resources/invoices';
import draftinvoices from './resources/invoices/draft';
import draftinvoiceitems from './resources/invoiceitems/draft';
import skus from './resources/skus';
import kegs from './resources/kegs';
import products from './resources/products';
import locations from './resources/locations';
import locationDetails from './resources/starlight/dashboard/locations/details';
import starlightkegs from './resources/starlight/kegs/list';
import starlightkegslocation from './resources/starlight/kegs/list/location';
import starlightkegsMovement from './resources/starlight/kegs/journey/movement';
import starlightkegsEvent from './resources/starlight/kegs/journey/event';

import KegnovaLoadingScreen from "./components/loaders/KegnovaLoadingScreen";

const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'es') {
        // return import('./i18n/es').then(messages => messages.default);
    // }
    // Always fallback on english
    return englishMessages;
}, 'en');

const Loading = () => {
    return (
        <React.Fragment>
            <KegnovaLoadingScreen />
        </React.Fragment>
    );
};

const App = () => {
    const [dataProvider, setDataProvider] = useState<DataProvider>();

    useEffect(() => {
        let restoreFetch;

        const fetchDataProvider = async () => {
            restoreFetch = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );
            const dataProviderInstance = await dataProviderFactory(
                process.env.REACT_APP_DATA_PROVIDER || ''
            );
            setDataProvider(
                // GOTCHA: dataProviderInstance can be a function
                () => dataProviderInstance
            );
        };

        fetchDataProvider();

        return restoreFetch;
    }, []);

    if (!dataProvider) {
        return (
            <Loading />
        );
    }

    return (
        <Admin disableTelemetry
            title=""
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            loading={Loading}
            layout={Layout}
            i18nProvider={i18nProvider}
        >
            <Resource name="cycles" {...cycles} />
            <Resource name="invoices/draft" {...draftinvoices} />
            <Resource name="invoiceitems/draft" {...draftinvoiceitems} />
            <Resource name="invoices" {...invoices} />
            <Resource name="skus" {...skus} />
            <Resource name="starlight/dashboard/locations/details" {...locationDetails} />
            <Resource name="starlight/kegs/list" {...starlightkegs} />
            <Resource name="starlight/kegs/list/location" {...starlightkegslocation} />
            <Resource name="starlight/kegs/journey/movement" {...starlightkegsMovement} />
            <Resource name="starlight/kegs/journey/event" {...starlightkegsEvent} />
            <Resource name="locations" {...locations} />
            <Resource name="kegs" {...kegs} />
            <Resource name="products" {...products} />
        </Admin>
    );
};

export default App;
