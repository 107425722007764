import { useState, useEffect } from "react"

export default function GetMarketColor(context) {
    const [marketColor, setMarketColor] = useState(null);
    // const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async ()=>{
            let colorCode = '#525558';
            const userUuid = localStorage.getItem('cUuid');
            switch (userUuid) {
                case process.env.REACT_APP_COUNTRY_UUID_AU:
                    colorCode = '#496901';
                    break;
                case process.env.REACT_APP_COUNTRY_UUID_NZ:
                    colorCode = '#607080';
                    break;
                case process.env.REACT_APP_COUNTRY_UUID_UK:
                    colorCode = '#7E0819';
                    break;
                case process.env.REACT_APP_COUNTRY_UUID_US:
                    colorCode = '#201d56';
                    break;
                default:
                    colorCode = '#525558';
                    break;
            }
            // setLoading(false);
            setMarketColor(colorCode);
        })()
    })
    return [marketColor];
}
