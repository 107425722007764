import React from "react";
import styled, { keyframes } from 'styled-components';
import { tada } from 'react-animations';
import logo from "../../assets/logos/KegstarLogo_Star.svg";

const bounceAnimation = keyframes`${tada}`;

const BouncingWrapper = styled.div`
  animation: 1s ${bounceAnimation} infinite;
`;

const KegnovaLoadingScreen = () => {

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)', zIndex: 10000
        }}>
            <BouncingWrapper>
                <img src={logo} alt="Kegstar" style={{width: '75px'}} />
            </BouncingWrapper>
        </div>
    )
}

export default KegnovaLoadingScreen;
