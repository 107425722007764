import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Box, Typography} from "@material-ui/core";
import {
    SimpleForm,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import StarlightIcon from "../../assets/images/starlight-kegs-icon.png";
import ReadyToFillIcon from "../../assets/images/kegs-empty.png";
import FilledIcon from "../../assets/tracks/images/custom-icons/kegs.svg";
import DistributorIcon from "../../assets/tracks/images/custom-icons/kegs.svg";
import InTransitIcon from "../../assets/tracks/images/custom-icons/truck-no-logo.svg";
import VenueIcon from "../../assets/tracks/images/custom-icons/glass.svg";
import ReadyForCollectionIcon from "../../assets/tracks/images/custom-icons/truck.svg";
import NoStarlightKegIcon from '@material-ui/icons/NotListedLocation';
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KegnovaDataLoader from "../../components/loaders/KegnovaDataLoader";

const useStyles = makeStyles((theme) => ({
    summariesFilter: {
        '& .MuiCardContent-root': {
            display: 'flex'
        },
    },
    summaryLocationFilter: {
        paddingRight: '10px',
        width: '320px',
        display: 'none' // @todo temporarily disabled
    },
    summarySkuFilter: {
        paddingRight: '10px',
        width: '125px'
    },
    summaryIconBox: {
        textAlign: 'center'
    },
    summaryIcons: {
        width: '70px', height: '80px'
    },
    mainLabel: {
        textAlign: 'center',
        color: "#fff",
        fontSize: '1.25rem'
    },
    subLabel: {
        textAlign: 'center',
        color: "#fff",
        fontSize: '1.25rem'
    },
    mainValue: {
        textAlign: 'center',
        color: "#90be51",
        fontSize: '1.5rem'
    }
}));


const Summary = ({summaryData, loading, handleLocationFilterChange, ...props}) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            { loading &&
            <Box display="flex" justifyContent="center" m={1} p={1} >
                <Box m={5}>
                    <KegnovaDataLoader />
                </Box>
            </Box>
            }
            <React.Fragment>
                <Box m={1} style={{backgroundColor: '#1f2124', marginBottom: '-56px'}}>
                    <Box display="flex" flexDirection="row-reverse" >
                        <SimpleForm toolbar={false} style={{padding: '0px', margin: '0px', display: 'flex'}} id={'summariesFilter'} className={classes.summariesFilter}>
                            {/*<ReferenceInput source="locationUuid" reference="locations" label={'Locations'}*/}
                            {/*                filter={{organisationUuid: localStorage.getItem('oUuid')}}*/}
                            {/*                sort={{name: 'asc'}} className={classes.summaryLocationFilter}*/}
                            {/*                allowEmpty={true} emptyText={'ALL'} onChange={handleLocationFilterChange}>*/}
                            {/*    <AutocompleteInput optionText="name" id={'summaryLocationsFilterField'} options={{InputProps: { endAdornment: (<IconButton><KeyboardArrowDownIcon style={{color: '#fff'}}/></IconButton>)}}} />*/}
                            {/*</ReferenceInput>*/}
                            {/*<ReferenceInput source="skuUuid" reference="skus" label={'SKU'}*/}
                            {/*                className={classes.summarySkuFilter}*/}
                            {/*                allowEmpty={true} emptyText={'ALL'} style={{width: '100px'}}>*/}
                            {/*    <AutocompleteInput optionText="name" id={'summarySkusFilterField'} options={{InputProps: { endAdornment: (<IconButton><KeyboardArrowDownIcon style={{color: '#fff'}}/></IconButton>)}}} />*/}
                            {/*</ReferenceInput>*/}
                        </SimpleForm>
                    </Box>
                </Box>
                { !loading && summaryData ?
                    <Box m={1} style={{backgroundColor: '#1f2124'}}>
                        <Grid container spacing={4} alignContent={'center'} justify={'center'}>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={StarlightIcon} alt={''} className={classes.summaryIcons} /></Box>
                                    <Box m={0.5} className={classes.mainLabel}>Starlight Kegs</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsAtLocation}</Box>
                                    <Box m={0.5} className={classes.subLabel}>Kegs being <br/>tracked</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={ReadyToFillIcon} alt={''} style={{width: '90px', height: '80px'}}/></Box>
                                    <Box m={0.5} className={classes.mainLabel}>Ready for Filling</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsReadyToFilled}</Box>

                                </Box>
                            </Grid>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={FilledIcon} alt={''} className={classes.summaryIcons} /></Box>
                                    <Box m={0.5} className={classes.mainLabel}>Filled</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsFilled}</Box>

                                </Box>
                            </Grid>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={InTransitIcon} alt={''} className={classes.summaryIcons} /></Box>
                                    <Box m={0.5} className={classes.mainLabel}>In Transit</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsInTransit}</Box>
                                    <Box m={0.5} className={classes.subLabel}>from Brewery</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={DistributorIcon} alt={''} className={classes.summaryIcons} /></Box>
                                    <Box m={0.5} className={classes.mainLabel}>Distributor</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsAtDistributor}</Box>

                                </Box>
                            </Grid>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={VenueIcon} alt={''} className={classes.summaryIcons} /></Box>
                                    <Box m={0.5} className={classes.mainLabel}>Venue</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsPouring}</Box>
                                    <Box m={0.5} className={classes.subLabel}>Pouring</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={1.95}>
                                <Box m={1} display="grid" justifyContent="center" alignItems="center">
                                    <Box m={0.5} className={classes.summaryIconBox}><img src={ReadyForCollectionIcon} alt={''} className={classes.summaryIcons} /></Box>
                                    <Box m={0.5} className={classes.mainLabel}>Ready for Collection</Box>
                                    <Box m={0.5} className={classes.mainValue}>{summaryData.kegsReadyForCollection}</Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box m={1} style={{backgroundColor: '#1f2124'}}>
                        <Box m={5}>
                            <Typography variant={'h5'} style={{color: '#fff', paddingBottom: '3em'}}>
                                <NoStarlightKegIcon fontSize={'large'} />
                                No Starlight keg(s) on this location</Typography>
                            <Box m={6}></Box>
                        </Box>
                    </Box>
                }
            </React.Fragment>
        </React.Fragment>
    )
}

export default Summary;
