import * as React from 'react';
import {Container, LinearProgress, Paper, Typography} from "@material-ui/core";
import { useParams } from 'react-router-dom';
import {useLoading, useQuery} from 'react-admin';
import Box from "@material-ui/core/Box";
import Summary from "./Summary";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import axios from "axios";
import KegsList from "../../resources/starlight/kegs/list/KegsList";
import {checkMystarStarlightAccess, UnauthorisedMystarStarlightAccess} from "../../utilities/Common";

const useStyles = makeStyles((theme) => ({

    detailsTableCellLeft: {
        width: '95px',
        fontSize: '1rem',
        fontWeight: '400'
    },
    detailsTableCellRight: {
        fontSize: '1rem',
        fontWeight: '400'
    },
    orientationImage: {
        width: '60px',
        filter: 'invert(100%)'
    }

}));
const GetCurrentLocation = ({locationUuid, lastPingTime}) => {
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'locations',
        payload: { id: locationUuid }
    });

    if (loading) return <LinearProgress />;
    if (error) return  "Error";
    if (!data) return null;

    return (
        <Paper square={true}>
            <Box m={2} textAlign={'center'} style={{height: '30px'}}><Typography variant={'h6'}>Current Location</Typography></Box>
            <Box m={2} textAlign={'center'} style={{height: '40px'}}>{ data.name}</Box>
            <Box m={2} textAlign={'center'} style={{height: '70px'}}>{ data.address} </Box>
            <Box m={2} textAlign={'center'} style={{height: '30px'}}>{ new Date(lastPingTime).toLocaleString()}</Box>
        </Paper>
    )
};
const Kegs = () => {
    const {locationUuid} = useParams();
    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/summary?organisationUuid=${localStorage.getItem('oUuid')}`);
    const [summaryUrl, setSummaryUrl] = useState(baseUrl);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);
    const [summaryData, setSummaryData] = useState(null);
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(summaryUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data[0];
                setSummaryData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [summaryUrl]);
    const handleLocationFilterChange = (locationUuid) => {
        // console.log(locationUuid);
        if(locationUuid) {
            setSummaryUrl(baseUrl+'&locationUuid='+locationUuid);
            setLocation(locationUuid);
        } else {
            setSummaryUrl(baseUrl);
            setLocation(null);
        }
    }

    let kegsListProps = {
        basePath: "/starlight/kegs/list",
        resource: "starlight/kegs/list"
    }

    if ( !checkMystarStarlightAccess() ) {
        return (
            <UnauthorisedMystarStarlightAccess />
        )
    }

    return (
        <React.Fragment>
            <Container style={{ backgroundColor: '#fff' }} >
                <Box m={1} style={{marginLeft: '1px'}} display={'flex'} >
                    <Box m={1} flexGrow={1}><Typography variant={'h5'}>Starlight Keg Cycle List </Typography></Box>
                    <Box m={1}>
                        <Link href={ `/#/starlight/dashboard`}>
                            <Button variant={'outlined'}>View Keg Cycle Summary</Button>
                        </Link>
                    </Box>
                </Box>
            </Container>

            <Box style={{backgroundColor: '#e7e6e7', width: '100vw', height: '100%'}}>
                <Container style={{ maxWidth: '1230px', marginTop: '16px' }} >
                    <Box>
                        <Summary summaryData={summaryData} loading={loading} handleLocationFilterChange={handleLocationFilterChange} />
                    </Box>
                    <Box m={4}></Box>
                    <Box>
                        <KegsList {...kegsListProps} locationUuid={locationUuid} />
                    </Box>

                </Container>
            </Box>
        </React.Fragment>
    )
}


export default Kegs;
