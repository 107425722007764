// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const KegsMovementExport = (records) => {
    const dataForExport = records.map(record => {
        const {
            id,	kegUuid, organisationName, kegLastUpdate, locationsId,	locationRole,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'kegSerialNumber', 'locationName', 'locationType', 'kegArrivedAt', 'kegLastPing', 'kegFilledState', 'noOfDays', 'kegAVGTemperature', 'locationLatitude', 'locationLongitude', 'locationAddress'
        ], // order fields in the export
        rename:[
            'Serial Number', 'Location', 'Type',	'Arrived At',	'Last Ping Date',	'Last Known Status At Location',	'No of Days',	'Average Temperature (°C)', 'Latitude', 'Longitude', 'Address'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'KegsMovement_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default KegsMovementExport;
