// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const KegsListExport = (records) => {
    //
    const dataForExport = records.map(record => {
        const {
            id,	turnEndOrganisationUuid, locationUuid, turnStartAt, turnEndAt, kegLastPing, leftAtProducer, kegStatus,
            kegsReadyForCollection, kegsReadyForCollectionEvent, kegsReadyForCollectionAt, kegUuid, turnEndOrganisationName,
            cyclesUuid, lastKnownLocationsUuid,
            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field

        return dataForExport;
    });

    jsonExport(dataForExport, {
        headers: [
            'kegSerialNumber',	'deviceId', 'cycleStartDate', 'cycleStartLocation',
            'distributorAt',	'distributorLocation', 'venueAt',	'venueLocation', 'readyForCollection', 'filledAt', 'lastKnownLocations', 'kegIsFull', 'currentTemp',
            'cycleStopAt'


        ], // order fields in the export
        rename:[
            'Keg Serial Number', 'Device Id','Cycle Start Date', 'Cycle Start Location',
            'At Distributor',	'Distributor Location', 'At Venue',	'Venue Location', 'Ready For Collection', 'Filled At', 'Last Known Locations', 'Keg is Full', 'Last Known Temp',
            'Cycle Stop At'
        ]
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'KegsList_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default KegsListExport;
