import React, {PureComponent} from 'react';
import * as kegsImages from './images';
import styles from './styles.styl';

const SkuTypeImage = ({skuId, style={width: '176px'}}) => {
    const getImage = () => {
        // TODO Images and SKU types must be matched properly
        switch (skuId) {
            case 1: // KS50A (din 50)
            case 2: // KS50D (din 50)
                return kegsImages.generic;

            case 3: // KS30S (euro 30)
            case 4: // KS30D (din 30)
                return kegsImages.us30;

            case 5: // KS1/2D (din 58.60)
                return kegsImages.generic;

            case 6: // KS1/6D (din 19.50)
                return kegsImages.euro;

            case 7: // KS9GC (cask 40.90)
                return kegsImages.generic;

            case 8: // KS50S (din 50)
                return kegsImages.generic;

            default:
                return kegsImages.generic;
        }
    }

    const image  = getImage();

    return (
        <img
        className={styles.image}
        src={image.default}
        srcSet={image.srcSet}
        style={style} />
    )
}

export default SkuTypeImage;
