import React, { useState, useRef, useEffect } from "react";
import ReactMapGL, { Marker, FlyToInterpolator, Popup, NavigationControl, GeolocateControl, ScaleControl } from "react-map-gl";
import useSupercluster from "use-supercluster";
import KegstarKegWhiteIcon from "../../assets/images/starlight-kegs-icon.png";
import Box from "@material-ui/core/Box";
import axios from 'axios';
import Typography from "@material-ui/core/Typography";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Link} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {MarketGeoCoordinates} from "../../utilities/Common";
import SignalAnimation from "../../components/loaders/SignalAnimation";

const useStyles = makeStyles(theme => ({
    icon: {
        fill: '#fff',
    },
}));

export default function KegsSummaryMap({locationUuid= null}) {

    const classes = useStyles();

    const [viewport, setViewport] = useState({
        latitude: MarketGeoCoordinates[localStorage.getItem('mId')].latitude,
        longitude: MarketGeoCoordinates[localStorage.getItem('mId')].longitude,
        width: "100%",
        height: "500px",
        zoom: 2
    });
    const mapRef = useRef();

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [eventType, setEventType] = useState(null);
    const [results, setResults] = useState([]);
    const [points, setPoints] = useState([]);
    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+"/starlight/dashboard/locations/insights?organisationUuid="+localStorage.getItem('oUuid'));
    const [insightsUrl, setInsightsUrl] = useState(baseUrl);
    const [kegsAtLocation, setKegsAtLocation] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                let url = baseUrl;
                if(locationUuid){
                    // setInsightsUrl(baseUrl+'&locationUuid='+locationUuid);
                    url = url+'&locationUuid='+locationUuid;
                }
                if(eventType){
                    // setInsightsUrl(baseUrl+'&eventType='+eventType);
                    url = url+'&eventType='+eventType;
                }

                const result = await axios.get(url, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                // const data = result.data;

                const results = result ? result.data.slice(0, 5000) : [];

                if(results.length > 0) {

                    setResults(results);
                    setViewport({
                        latitude: parseFloat(results[0].locationLatitude),
                        longitude: parseFloat(results[0].locationLongitude),
                        width: "100%",
                        height: "550px",
                        zoom: 3
                    });

                    const newPoints = results.map(record => ({
                        type: "Feature",
                        properties: { cluster: false,
                            locationUuid: record.locationUuid,
                            cycleUuid: record.cycleUuid,
                            kegUuid: record.kegUuid,
                            locationName: record.locationName,
                            temperature: record.temperature,
                            kegSerialNumber: record.kegSerialNumber,
                            kegFilledState: record.kegFilledState,
                            kegTurnUuid: record.kegTurnUuid,
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [
                                parseFloat(record.locationLongitude),
                                parseFloat(record.locationLatitude)
                            ]
                        }
                    }));
                    setPoints(newPoints);

                } else {
                    setResults([]);
                    setPoints([]);
                    setViewport({
                        latitude: MarketGeoCoordinates[localStorage.getItem('mId')].latitude,
                        longitude: MarketGeoCoordinates[localStorage.getItem('mId')].longitude,
                        width: "100%",
                        height: "500px",
                        zoom: 2
                    });

                }

            } catch (error) {
                console.log(error.response)
            }
        })();
    }, [insightsUrl, eventType, locationUuid]);

    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 20}
    });

    const CustomPopup = ({index, marker, closePopup}) => {
        if (!marker) {
            return null;
        }
        if (!marker.properties.locationName) {
            setSelectedIndex(null);
            return null;
        }
        let [longitude, latitude] = marker.geometry.coordinates;

        return (
            <Popup
                latitude={latitude}
                longitude={longitude}
                onClose={closePopup}
                closeButton={true}
                closeOnClick={false}
                offsetLeft={18}
            >
                { kegsAtLocation === 1 &&
                    <Link href={ `/#/starlight/kegs/${marker.properties.cycleUuid}/journey`}>
                        <Typography variant={'h6'}>{marker.properties.kegSerialNumber}</Typography>
                    </Link>
                }
                <Typography variant={ kegsAtLocation > 1 ? 'h6' : 'subtitle1' }>
                    <Link href={ `/#/starlight/kegs/list/${marker.properties.locationUuid}`}>
                        {marker.properties.locationName}
                    </Link>
                </Typography>
                {/*<Typography variant={'body1'}>Status: <strong>{ (marker.properties.kegFilledState) ? 'Full' : 'Empty'}</strong> </Typography>*/}
                {/*<Typography variant={'body1'}>Temp: <strong>{ marker.properties.temperature } &deg;</strong> </Typography>*/}
                <Typography variant={'body1'}>Kegs At Location: <strong>{ kegsAtLocation } </strong> </Typography>
            </Popup>
        )};

    const CustomMarker = ({index, marker, openPopup, latitudes, longitudes}) => {
        let latCount = latitudes.reduce((n, x) => n + (x === marker.latitude), 0);
        // let longCount = longitudes.reduce((n, x) => n + (x === marker.latitude), 0);
        let kegsCount = latCount;

        return (
            <Marker
                key={`marker-${marker.kegSerialNumber}`}
                longitude={marker.longitude}
                latitude={marker.latitude}>
                <React.Fragment>
                    <SignalAnimation />
                    <button className="starlight-keg-marker" onClick={() => openPopup(index, kegsCount)}>
                        <img src={KegstarKegWhiteIcon} alt="Starlight Keg" />
                    </button>
                </React.Fragment>
            </Marker>
        )};

    const closePopup = () => {
        setSelectedIndex(null);
    };
    const openPopup = (index, kegsCount) => {
        setSelectedIndex(index);
        setKegsAtLocation(kegsCount);
    }
    const remove = (index) => {
        setSelectedIndex(null);
    }
    const handleEventTypeChange = (event) => {
        // console.log(event.target.value);
        setEventType(event.target.value);
        if(event.target.value) {
            setInsightsUrl(baseUrl+"&eventType="+event.target.value);
        } else {
            setInsightsUrl(baseUrl);
        }
        setSelectedIndex(null);
    };

    let latitudes = [];
    let longitudes = [];

    return (
        <div>
            <Box m={3}></Box>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography variant={'h5'}>Location Insights</Typography>
                </Box>
                <Box >
                    <FormControl variant="filled" style={{width: '180px'}} >
                        <InputLabel id="event-type-label" style={{color: '#fff'}}>Keg Status</InputLabel>
                        <Select
                            labelId="event-type-label"
                            id="summary-event-type-select"
                            value={eventType}
                            onChange={handleEventTypeChange} style={{backgroundColor: '#1f2124', color: '#fff'}}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            <MenuItem value={1}>Ready for filling</MenuItem>
                            <MenuItem value={2}>Filled</MenuItem>
                            <MenuItem value={3}>In Transit</MenuItem>
                            <MenuItem value={4}>At Distributor</MenuItem>
                            <MenuItem value={5}>Pouring at Venue</MenuItem>
                            <MenuItem value={6}>Empty at Venue</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            { results &&
            <ReactMapGL
                {...viewport}
                maxZoom={200}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                onViewportChange={newViewport => {
                    setViewport({ ...newViewport });
                }}
                ref={mapRef}
                mapStyle={'mapbox://styles/kegstartech/cklub9v6l393j17r1kj5pe7wk'}

            >
                {
                    clusters.map((cluster, index) => {
                        let [longitude, latitude] = cluster.geometry.coordinates;
                        latitudes.push(latitude);
                        longitudes.push(longitude);

                        const {
                            cluster: isCluster,
                            point_count: pointCount
                        } = cluster.properties;

                        if (isCluster) {
                            return (
                                <Marker
                                    key={`cluster-${cluster.id}`}
                                    latitude={latitude}
                                    longitude={longitude}
                                >
                                    <div
                                        className="cluster-marker"
                                        style={{
                                            width: `${10 + (pointCount / points.length) * 20}px`,
                                            height: `${10 + (pointCount / points.length) * 20}px`
                                        }}
                                        onClick={() => {

                                            const expansionZoom = Math.min(
                                                supercluster.getClusterExpansionZoom(cluster.id),
                                                30
                                            );

                                            setViewport({
                                                ...viewport,
                                                latitude,
                                                longitude,
                                                zoom: expansionZoom,
                                                transitionInterpolator: new FlyToInterpolator({
                                                    speed: 2
                                                }),
                                                transitionDuration: "auto"
                                            });
                                            setSelectedIndex(null);
                                        }}
                                    >
                                        {pointCount}
                                    </div>
                                </Marker>
                            );
                        }


                        return (
                            <React.Fragment>
                                <CustomMarker
                                    key={`marker-${cluster.properties.kegSerialNumber}`}
                                    index={index}
                                    marker={{latitude: latitude, longitude: longitude}}
                                    openPopup={openPopup}
                                    latitudes={latitudes}
                                    longitudes={longitudes}
                                />

                            </React.Fragment>
                        );
                    })}
                {
                    selectedIndex !== null &&
                    <CustomPopup
                        index={selectedIndex}
                        marker={clusters[selectedIndex]}
                        closePopup={closePopup}
                        remove={remove}
                    />
                }
                <NavigationControl style={{right: 10, top: 10}} />
                <ScaleControl style={{left: 10, top: 10}} />
                <GeolocateControl style={{left: 10, top: 30}} />
                {/*<FullscreenControl style={{left: 10, top: 60}} />*/}
            </ReactMapGL>
            }
        </div>
    );
}
