// @ts-nocheck
import * as React from 'react';
import {FC, Fragment, useRef} from 'react';
import {
    useLoading,
    List,
    ListProps,
    Datagrid,
    TextField,
    Filter,
    FilterProps,
    ReferenceInput,
    AutocompleteInput,
    DateField,
    useAuthenticated,
    TextInput,
    SelectInput,
    DateInput, NumberInput, Pagination
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Box, CircularProgress, Container, Grid, Card, Button, Modal, Paper} from "@material-ui/core";
import KegsListExport from "./KegsListExport";
import KegListActions from "./KegListActions";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {CustomPagination} from "../../../../utilities/Common";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

const useStyles = makeStyles(theme => ({
    links: {
        color: '#2d9edf'
    },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    cycleStatusFilter: {
        width: '50px'
    },
    locationDetailsFilter: {
        width: '300px',
    },
    serialNumberFilter: {
        width: '150px',
    },
    deviceIdFilter: {
        width: '150px',
    },
    readyForCollection: {
        width: '200px',
    },
    filledState: {
        width: '175px',
    },
    isTurnEnd: {
        width: '175px',
    },
    turnDays: {
        width: '106px',
    },
    closeFilterButton: {padding: '0px', marginTop: '18px'}
}));

const getStartDateFrom = () => {
    let days = 90;
    let result = new Date( new Date() - days * 24 * 60 * 60 * 1000);
    return result.toISOString().slice(0, 10);
}
const getStartDateTo = () => {
    return new Date().toISOString().slice(0, 10);
}

const ListFilters = (props: Omit<FilterProps, 'children'>) => {
    const classes = useStyles();

    return (
        <Filter {...props} >
            <ReferenceInput source="locationUuid" reference="starlight/kegs/list/location" label={'Location'}
                            filter={{organisationUuid: localStorage.getItem('oUuid'), isTurnEnd: 0}}
                            alwaysOn resettable perPage={10000} className={classes.locationDetailsFilter}>
                <AutocompleteInput optionText={"lastKnownLocation"} />
                {/*<AutocompleteInput optionText={(record)=> `${record.lastKnownLocation} | Turn Start At: ${ (new Date(record.turnStartAt).toLocaleString(navigator.language, { day: 'numeric', month: 'short', year: 'numeric', })) }` } />*/}
            </ReferenceInput>
            {/*<ReferenceInput source="kegUuid" reference="kegs" label={'Serial Number'}*/}
            {/*                sort={{name: 'asc'}} className={classes.serialNumberFilter}*/}
            {/*                filter={{organisationUuid: localStorage.getItem('oUuid')}}*/}
            {/*                alwaysOn resettable allowEmpty={true} emptyText={'ALL'}>*/}
            {/*    <AutocompleteInput optionText={'serialNumber'} />*/}
            {/*</ReferenceInput>*/}
            <TextInput source={'serialNumber'} label={'Serial Number'} resettable alwaysOn  />
            {/*<TextInput label={'Serial Number'} source={'serialNumber'} alwaysOn resettable={true} className={classes.serialNumberFilter} />*/}
            <TextInput label={'Device ID'} source={'deviceId'} alwaysOn resettable={true} />
            <SelectInput source="filledState" label={'Likely Full/Empty'} choices={[
                { id: 1, name: 'Full' },
                { id: 0, name: 'Empty' }
            ]} alwaysOn resettable={true} emptyText={'All'} className={classes.filledState}/>

            <SelectInput source="currentTemp" label={'Temperature'} choices={[
                { id: null, name: 'All' },
                { id: 0, name: 'Cold' },
                { id: 1, name: 'Just Right' },
                { id: 2, name: 'Warm' }
            ]} resettable={true} alwaysOn allowEmpty={false} emptyText={true} />

            <SelectInput source="lastKnownRole" label={'Role'} choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Warehouse' },
                { id: 2, name: 'Producer' },
                { id: 3, name: 'Distributor' },
                { id: 4, name: 'Venue' },
                { id: 5, name: 'Collector' },
            ]} resettable={true} alwaysOn allowEmpty={false} emptyText={true} />

            <SelectInput source="kegIsFull" label={'Ready For Collection'} choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' }
            ]} resettable={true} alwaysOn allowEmpty={false} emptyText={true}  className={classes.readyForCollection} />

            <DateInput label={'Cycle Start From'} source={'cycleStartDateFrom'} alwaysOn resettable={true}
                       options={{InputProps: {
                               endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"cycleStartDateFrom": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} fontSize={'small'} /> </IconButton> </Fragment>)
                           }}}
            />
            <DateInput label={'Cycle Start To'} source={'cycleStartDateTo'} alwaysOn resettable={true}
                       options={{InputProps: {
                               endAdornment: (<Fragment> <IconButton onClick={() => props.setFilters({"cycleStartDateTo": ''})} className={classes.closeFilterButton} > <Close className={classes.closeFilterIcon} fontSize={'small'} /> </IconButton> </Fragment>)
                           }}}
            />
            { (localStorage.getItem('cId') === "1" || localStorage.getItem('cId') === "2") &&
            <SelectInput source="cycleStatus" label={'Keg Cycle Status'} choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Complete' },
                { id: 0, name: 'In-progress' }
            ]} resettable={true} allowEmpty={false} emptyText={true}  />
            }

            {/*<NumberInput label={'Turn Days'} source={'turnDays'}  alwaysOn resettable={true} className={classes.turnDays} allowEmpty={true} />*/}
            {/*<BooleanInput label={'Turn Ended'} source={'isTurnEnd'} />*/}
            {/*<DateInput label={'Arrived At - From'} source={'currentLocationStartAtFrom'} resettable={true}*/}
            {/*/>*/}
            {/*<DateInput label={'Arrived At - To'} source={'currentLocationStartAtTo'} resettable={true}*/}
            {/*/>*/}
        </Filter>
    )
}
const RecordRowClick = (id, basePath, record) => {
    // console.log(id, basePath, record);
    let url = `/starlight/kegs/${record.cyclesUuid}/journey`;
    // return url;
    // e.preventDefault();
    // const recordPath = linkToRecord(basePath, id)
    window.open(`#${url}`);
    return false;
};
const KegsList: FC<ListProps> = ({locationUuid, ...props}) => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();
    const scrollable = useRef(null);
    const defaultStartDateFrom = getStartDateFrom();
    const defaultStartDateTo =  getStartDateTo();
    const scroll = (scrollOffset) => {
        scrollable.current.scrollLeft += scrollOffset;
    };
    const KegsListPagination = props => <Pagination style={{float: 'left'}} rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;
    return (
        <React.Fragment>
            <Box display="flex" flexDirection="row-reverse" >
                <Box>
                    <Button onClick={() => scroll(350)}> <KeyboardArrowRight /> </Button>
                </Box>
                <Box>
                    <Button onClick={() => scroll(-350)}> <KeyboardArrowLeft /> </Button>
                </Box>
            </Box>
            <Container style={{width: 'auto', overflowX: 'scroll', padding: '8px'}} ref={scrollable}>
                {/*{ loading &&*/}
                {/*    <Modal*/}
                {/*        open={loading}*/}
                {/*    >*/}
                {/*        <Box m={1} justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}*/}
                {/*             style={{backgroundColor: '#1f2124', borderColor: 'none', color: '#fff', borderRadius: 0}}>*/}
                {/*            <Box p={2} m={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>*/}
                {/*        </Box>*/}
                {/*    </Modal>*/}
                {/*}*/}
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', margin: '2px', marginBottom: '20px', padding:'5px'}}>
                    <Box p={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>
                </Box>
                }
                <List
                    {...props}
                    bulkActionButtons={false}
                    actions={<KegListActions />}
                    filters={<ListFilters />}
                    filter={{organisationUuid: localStorage.getItem('oUuid')}}
                    // filterDefaultValues={ locationUuid ? {locationUuid: locationUuid, isTurnEnd: 0} : null  }
                    filterDefaultValues={ locationUuid ? {organisationUuid: localStorage.getItem('oUuid'), locationUuid: locationUuid} : {organisationUuid: localStorage.getItem('oUuid'), cycleStartDateFrom: defaultStartDateFrom, cycleStartDateTo: defaultStartDateTo}  }
                    sort={{ field: 'cycleStartDate', order: 'desc' }}
                    exporter={KegsListExport}
                    pagination={<KegsListPagination />}
                >
                    <Datagrid style={{ maxWidth: '1230px'}} id={'starlight-location-details-table'} rowClick={RecordRowClick}>
                        <TextField label={'Serial Number'} source={'kegSerialNumber'} className={classes.links} />
                        <DateField label={'Cycle Start At' } source={'cycleStartDate'}  locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'} />
                        <DateField label={'Cycle End At' } source={'cycleStopAt'}  locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'} />
                        <TextField label={'Cycle Start At Location' } source={'cycleStartLocation'} />
                        <TextField label={'Last Known Location' } source={'lastKnownLocations'} />
                        <DateField label={'At Distributor On' } source={'distributorAt'}  locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'} />
                        <TextField label={'Distributor location'} source={'distributorLocation'}  />
                        <DateField label={'At Venue On' } source={'venueAt'}  locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'} />
                        <TextField label={'Venue location'} source={'venueLocation'}  />
                        <TextField label={'Full/Empty'} source={'kegIsFull'} />
                        <DateField label={'Filled Date' } source={'filledAt'}  locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'} />
                        <TextField label={'Last Known Temp'} source={'currentTemp'}  />
                        <TextField label={'Ready for Collection'} source={'readyForCollection'} />
                        <TextField label={'Device Id'} source={'deviceId'}  />
                    </Datagrid>
                </List>
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', margin: '2px', marginBottom: '20px', padding:'5px'}}>
                    <Box p={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>
                </Box>
                }
            </Container>
        </React.Fragment>
    );
};

export default KegsList;
