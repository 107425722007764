import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {},
    },
    billing: {
        updatepackagesuccessful: 'Successfully updated a package',
        updatepackagefailed: 'Error updating package details',
        updatingpackagedetails: 'Please wait... Updating package details.'
    },
    login: {
        invalidlogin: 'Invalid login. Try again.'
    },
    resources: {},
};

export default customEnglishMessages;
