// @ts-nocheck
import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';

const CyclesExport = (records) => {
    const dataForExport = records.map(record => {
        const {
            id,	uuid, startLocation, stopOrganisation,
            stopLocation, kegs, organisation, billingStartDispatch, billingStopDispatch, billingStopZone, billableDays,
            price, billingStartAt, billingStopAt, slowDays, lostDays, leaseOrder, product, billingLastLocation,	cycleId,	filledAt,
            kegId,	serialNumber,	skuName,	startOrganisationName,	billingLastDispatchCreatedAtDisplay, poNumber, stopLocationState, lastKnownLocationName,

            ...dataForExport } = record; // omit unecessary fields
        // dataForExport.fieldName = record.field.name; // add a field
        dataForExport.kegSerialNumber = (record.serialNumber) ? record.serialNumber : null;
        dataForExport.organisationName = (record.organisationName) ? record.organisationName : null;
        dataForExport.startLocationName = (record.startLocationName) ? record.startLocationName : null;
        dataForExport.stopOrganisationName = (record.startOrganisationName) ? record.stopOrganisationName : null;
        dataForExport.stopLocationName = (record.stopLocationName) ? record.stopLocationName : null;
        dataForExport.stopLocationRole = (record.stopLocationRole) ? record.stopLocationRole : null;
        dataForExport.stopLocationPostcode = (record.stopLocationPostcode) ? record.stopLocationPostcode : null;
        dataForExport.kegsSkuName = (record.skuName) ? record.skuName : null;
        dataForExport.productName = (record.productName) ? record.productName : null;
        dataForExport.batchNumber = (record.batchNumber) ? record.batchNumber : null;
        dataForExport.filledAtDisplay = (record.filledAtDisplay) ? record.filledAtDisplay : null;
        dataForExport.billingLastDispatchId = (record.billingLastDispatchId) ? record.billingLastDispatchId : null;
        dataForExport.poNumber = (record.poNumber) ? record.poNumber : null;
        dataForExport.stopLocationState = (record.stopLocationState) ? record.stopLocationState : null;

        if(parseInt(localStorage.getItem('cId')) !== 3 && parseInt(localStorage.getItem('cId')) !== 4){
            dataForExport.billingLastLocationName = record.lastKnownLocationName;
            dataForExport.billableDays = (record.billableDays) ? record.billableDays : null;
        }

        return dataForExport;
    });
    let headers = [
        'kegSerialNumber', 'order', 'age', 'billableDays', 'organisationName', 'cycleStatus', 'stopReason', 'billingStartAtDisplay', 'billingStopAtDisplay',
        'startLocationName', 'stopOrganisationName', 'stopLocationName', 'stopLocationPostcode', 'stopLocationRole', 'stopLocationState', 'kegsSkuName', 'productName', 'batchNumber',
        'filledAtDisplay', 'poNumber', 'billingLastDispatchId', 'billingLastLocationName', 'billingLastDispatchCreatedAt'
    ];
    let rename = [
        'Keg', 'Order', 'Age', 'Billable Days', 'Organisation', 'Status', 'Reason', 'Start Date', 'End Date',
        'Start Location', 'Stop Organisation', 'Stop Location', 'Postcode', 'Type', 'State', 'SKU', 'Product', 'Batch Number',
        'Filled At', 'PO Number', 'Billing Last Dispatch Id', 'Billing Last Location', 'Billing Last Dispatch Created At'
    ];

    if(parseInt(localStorage.getItem('cId')) === 3 || parseInt(localStorage.getItem('cId')) === 4){
        headers = [
            'kegSerialNumber', 'order', 'age', 'organisationName', 'cycleStatus', 'stopReason', 'billingStartAtDisplay', 'billingStopAtDisplay',
            'startLocationName', 'stopOrganisationName', 'stopLocationName', 'stopLocationPostcode', 'stopLocationRole', 'stopLocationState', 'kegsSkuName', 'productName', 'batchNumber',
            'filledAtDisplay', 'poNumber', 'billingLastDispatchId', 'billingLastDispatchCreatedAt'
        ];
        rename = [
            'Keg', 'Order', 'Age', 'Organisation', 'Status', 'Reason', 'Start Date', 'End Date',
            'Start Location', 'Stop Organisation', 'Stop Location', 'Postcode', 'Type', 'State', 'SKU', 'Product', 'Batch Number',
            'Filled At', 'PO Number', 'Billing Last Dispatch Id', 'Billing Last Dispatch Created At'
        ];
    }

    jsonExport(dataForExport, {
        headers: headers, // order fields in the export
        rename: rename
    }, (err, csv) => {
        let today = new Date();
        downloadCSV(csv, 'Cycles_' + today.toISOString().split('T')[0]); // download as 'posts.csv` file
    });

};

export default CyclesExport;
