import * as React from 'react';
import {Container, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
// import KegSummary from "./KegSummary";
import Summary from "./Summary";
import KegsSummaryMap from "./KegsSummaryMap";
import LocationDetailsList from "../../resources/starlight/dashboard/locations/details/LocationDetailsList";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import {useEffect, useState} from "react";
import axios from "axios";
import {checkMystarStarlightAccess, UnauthorisedMystarStarlightAccess} from "../../utilities/Common";
import Averages from "./Averages";

const Dashboard = props => {
    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/summary?organisationUuid=${localStorage.getItem('oUuid')}`);
    const [summaryUrl, setSummaryUrl] = useState(baseUrl);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);
    const [summaryData, setSummaryData] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(summaryUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data[0];
                setSummaryData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [summaryUrl]);
    const handleLocationFilterChange = (locationUuid) => {
        // console.log(locationUuid);
        if(locationUuid) {
            setSummaryUrl(baseUrl+'&locationUuid='+locationUuid);
            setLocation(locationUuid);
        } else {
            setSummaryUrl(baseUrl);
            setLocation(null);
        }
    }

    let locationDetailsProps = {
        basePath: "/starlight/dashboard/locations/details",
        resource: "starlight/dashboard/locations/details"
    }

    if ( !checkMystarStarlightAccess() ) {
        return (
            <UnauthorisedMystarStarlightAccess />
        )
    }

    return (
        <React.Fragment>
            <Container style={{ backgroundColor: '#fff' }} >
                <Box m={1} style={{marginLeft: '1px'}} display={'flex'} >
                    <Box m={1} flexGrow={1}><Typography variant={'h5'}>Starlight Keg Cycle Summary </Typography></Box>
                    <Box m={1} style={{marginRight: '2%'}}>
                        <Link href={ `/#/starlight/kegs/list`}>
                            <Button variant={'outlined'}>View Keg Cycle List</Button>
                        </Link>
                    </Box>
                </Box>
            </Container>

            <Box style={{backgroundColor: '#e7e6e7', width: '100vw', height: '100%'}}>
                <Container style={{ maxWidth: '1230px', marginTop: '16px' }} >
                    <Box>
                        <Summary summaryData={summaryData} loading={loading} handleLocationFilterChange={handleLocationFilterChange} />
                    </Box>

                    {/*<Box m={1}>*/}
                    {/*    <EventSummary />*/}
                    {/*</Box>*/}

                    <Box m={1}>
                        <Averages />
                    </Box>


                    <Box m={1}>
                        <KegsSummaryMap locationUuid={location} />
                    </Box>

                    <Box>
                        <LocationDetailsList {...locationDetailsProps}/>
                    </Box>


                </Container>
                <Box m={20}></Box>
            </Box>

        </React.Fragment>
    )
}


export default Dashboard;
