// @ts-nocheck
import * as React from 'react';
import { FC } from 'react';
import {
    useLoading,
    List,
    ListProps,
    Datagrid,
    TextField,
    BooleanField,
    Filter, NumberInput,
    FilterProps, ReferenceInput, AutocompleteInput, DateField, useAuthenticated, FunctionField, TextInput, SelectInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {Box, CircularProgress, Container} from "@material-ui/core";
import KegMovementActions from "./KegMovementActions";
import KegsMovementExport from "./KegsMovementExport";

const useStyles = makeStyles(theme => ({
    links: {
        color: '#2d9edf'
    },
    hiddenOnSmallScreens: {
        display: 'table-cell',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    cycleStatusFilter: {
        width: '50px'
    },
    locationDetailsFilter: {
        width: '285px',
    },
    serialNumberFilter: {
        width: '140px',
    },
    deviceIdFilter: {
        width: '120px',
    },
    readyForCollection: {
        width: '200px',
    }
}));

const ListFilters = (props: Omit<FilterProps, 'children'>) => {
    const classes = useStyles();

    return (
        <Filter {...props} >
            <ReferenceInput source="locationUuid" reference="/starlight/kegs/${cycleUuid}/journey" label={'Location'}
                            filter={{organisationUuid: localStorage.getItem('oUuid')}}
                            alwaysOn resettable className={classes.locationDetailsFilter} perPage={1000}>
                <AutocompleteInput optionText={"lastKnownLocation"} />
                {/*<AutocompleteInput optionText={(record)=> `${record.lastKnownLocation} | Cycle Start At: ${ (new Date(record.turnStartAt).toLocaleString(navigator.language, { day: 'numeric', month: 'short', year: 'numeric', })) }` } />*/}
            </ReferenceInput>
            {/*<ReferenceInput source="kegUuid" reference="kegs" label={'Serial Number'}*/}
            {/*                sort={{name: 'asc'}} className={classes.serialNumberFilter}*/}
            {/*                alwaysOn resettable allowEmpty={true} emptyText={'ALL'}>*/}
            {/*    <AutocompleteInput optionText={'serialNumber'} />*/}
            {/*</ReferenceInput>*/}
            <TextInput source={'serialNumber'} label={'Serial Number'} resettable alwaysOn className={classes.serialNumberFilter} />
            {/*<TextInput label={'Serial Number'} source={'serialNumber'} alwaysOn resettable={true} className={classes.serialNumberFilter} />*/}
            <TextInput label={'Device ID'} source={'deviceId'} alwaysOn resettable={true} className={classes.deviceIdFilter} />
            <SelectInput source="filledState" label={'Likely Full/Empty'} choices={[
                { id: 1, name: 'Full' },
                { id: 0, name: 'Empty' }
            ]} alwaysOn resettable={true} emptyText={'All'}/>
            <SelectInput source="likelyToBeEmpty" label={'Ready For Collection'} choices={[
                { id: 1, name: 'Yes' },
                { id: 0, name: 'No' }
            ]} resettable={true} className={classes.readyForCollection} />
            <NumberInput label={'Turn Days'} source={'turnDays'} resettable={true} />
            {/*<BooleanInput label={'Turn Ended'} source={'isTurnEnd'} />*/}
            <SelectInput source="isTurnEnd" label={'Keg Cycle Status'} className={classes.isTurnEnd}  choices={[
                { id: null, name: 'All' },
                { id: 1, name: 'Complete' },
                { id: 0, name: 'In progress' }
            ]} resettable={true} alwaysOn allowEmpty={false} emptyText={true}/>
        </Filter>
    )
}

const KegsMovementList: FC<ListProps> = ({cycleUuid, ...props}) => {
    useAuthenticated();
    const loading = useLoading();
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container style={{ maxWidth: '1230px', padding: '6px'}}>
                { loading &&
                <Box justifyContent={'center'} justifyItems={'center'}  alignItems={'center'} alignContent={'center'}
                     style={{backgroundColor: '#1f2124', color: '#fff', margin: '2px', marginBottom: '20px', padding:'5px'}}>
                    <Box p={1}><CircularProgress size={20} color={'#fff'} /> Please wait...</Box>
                </Box>
                }
                <List
                    {...props}
                    bulkActionButtons={false}
                    sort={{ field: 'kegLastPing', order: 'asc' }}
                    filter={{cycleUuid: cycleUuid}}
                    filterDefaultValues={{cycleUuid: cycleUuid}}
                    actions={<KegMovementActions />}
                    exporter={KegsMovementExport}
                >
                    <Datagrid id={'starlight-kegs-journey-movement'}>
                        <TextField label={'Location'} source={'locationName'} />
                        <TextField label={'Type'} source={'locationType'} />
                        <DateField  label={'Arrived At'} source={'kegArrivedAt'} locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'}
                                    options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }}/>
                        <DateField  label={'Last Ping Date'} source={'kegLastPing'} locales={(localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB'}
                                    options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }} />
                        {/*<TextField  label={'Last Known Status At Location'} source={'kegFilledState'}  sortable={false}/>*/}
                        <TextField  label={'No of Days'} source={'noOfDays'} />
                        <FunctionField label="Average Temperature" render={record => (record.kegAVGTemperature) ? <span>{record.kegAVGTemperature} &deg;C</span> : "-" } />
                    </Datagrid>
                </List>
            </Container>
        </React.Fragment>
    );
};

export default KegsMovementList;
