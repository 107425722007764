// @ts-nocheck
import React, {useCallback} from "react";
import {
    Box,

    Container,

    Typography,
}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import InvoiceShow from "../../resources/invoices/InvoiceShow";
import {useQuery} from "react-admin";
import {useHistory} from "react-router";
import BackIcon from '@material-ui/icons/ArrowBack';
import Button from "@material-ui/core/Button";


const GetDraftInvoice = ( uuid ) => {

    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'invoices/draft',
        payload: {id: uuid}
    });

    if (!data) return null;

    return (data);
};

const InvoiceItems = () => {

    let {uuid} = useParams();
    const history = useHistory();

    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);


    const draftInvoiceRecord = GetDraftInvoice(uuid);

    return (
        <React.Fragment>

            <Container style={{ maxWidth: '1230px'}} >
                <Box m={1} style={{marginLeft: '0px', marginBottom: '25px'}}>
                    <Typography variant={'h4'}>Statements // Invoices </Typography>
                </Box>
            </Container>

            <Container style={{ maxWidth: '1230px' }} >
                <Button onClick={handleBack} style={{float: 'right'}}>
                    <BackIcon />
                </Button>
                <InvoiceShow record={draftInvoiceRecord} />
            </Container>
        </React.Fragment>
    );
};

export default InvoiceItems;
