// @ts-nocheck
import * as React from 'react';
import { useState} from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    CircularProgress,
    Container,

    Tabs,
    Tab,
    Typography,

}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLoading } from 'react-admin';
import KegnovaDataLoader from "../../components/loaders/KegnovaDataLoader";
import CycleList from "../../resources/cycles/CycleList";

const Cycles = () => {
    const loading = useLoading();
    const [tabValue, setTabValue] = useState(3);
    let cycleProps = {
        basePath: "/cycles",
        resource: "cycles"
    }
    const handleTabChange = (event, newValue) => {
        if(newValue === 0) {
            window.location = `${process.env.REACT_APP_TRACKS_MYSTAR_URL}/kegs/my-kegs`;
            return false;
        }
        if(newValue === 1) {
            window.location = `${process.env.REACT_APP_TRACKS_MYSTAR_URL}/kegs/at-risk-kegs?filter=proximity%3D"high"&limit=10&page=1&sort=-total`;
            return false;
        }
        if(newValue === 2) {
            window.location = `${process.env.REACT_APP_TRACKS_MYSTAR_URL}/kegs/locations`;
            return false;
        }
        setTabValue(newValue);
    };
    return (
        <React.Fragment>

            <Box m={3}  >
                <Typography variant={'h4'}>Kegs // Cycles </Typography>
            </Box>

            <Container style={{width: '100vw', maxWidth: '100vw'}}>
                <Box>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="keg tabs" >
                        <Tab label="Keg Summary" {...formTabProps(0)} style={{minWidth: '100px', fontWeight: 'bold', fontSize: '1rem', color: '#2d9edf', textTransform: 'none'}} />
                        <Tab label="My Kegs" {...formTabProps(1)} style={{minWidth: '100px', fontWeight: 'bold', fontSize: '1rem', color: '#2d9edf', textTransform: 'none'}} />
                        <Tab label="Locations" {...formTabProps(2)} style={{minWidth: '100px', fontWeight: 'bold', fontSize: '1rem', color: '#2d9edf', textTransform: 'none'}} />
                        <Tab label="Cycles" {...formTabProps(3)} style={{minWidth: '100px', fontWeight: 'bold', fontSize: '1rem', backgroundColor: '#e7e6e7',
                            borderTopLeftRadius: '5px', borderTopRightRadius: '5px', textTransform: 'none'}} />
                    </Tabs>
                    <hr style={{ marginTop: '-2px', height: '1px', color: '#1b1b1b', backgroundColor: '#1b1b1b',
                        border: 'solid 1px #1b1b1b' }}/>
                    <TabPanel value={tabValue} index={0}>
                        <KegnovaDataLoader />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <KegnovaDataLoader />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <KegnovaDataLoader />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                        <CycleList {...cycleProps} />
                    </TabPanel>
                </Box>
            </Container>

        </React.Fragment>
    );
};

Cycles.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// @ts-ignore
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function formTabProps(index) {
    return {
        id: `cycles-tab-${index}`,
        'aria-controls': `cycles-tabpanel-${index}`,
    };
}

export default Cycles;
