import * as React from 'react';
import {Chip, CircularProgress, Container, LinearProgress, Paper, Typography} from "@material-ui/core";
import { useParams } from 'react-router-dom';
import {useQuery} from 'react-admin';
import Box from "@material-ui/core/Box";
// import Summary from "./Summary";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import SkuTypeImage from "../../components/shared/SkuTypeImage";
import {makeStyles} from "@material-ui/core/styles";
import OrientationIcon from "../../assets/images/orientation.svg";
import KegJourneyMap from "./KegJourneyMap";
import {useEffect, useState} from "react";
import axios from "axios";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import MaterialTable from 'material-table';
import {
    checkMystarStarlightAccess, UnauthorisedMystarStarlightAccess
} from "../../utilities/Common";
// import api from "../../dataProvider/restApi";
import JourneyTimelineChart from "./JourneyTimelineChart";
// import SummaryBarChart from "./SummaryBarChart";
import KegsMovementList from "../../resources/starlight/kegs/journey/movement/KegsMovementList";
import KegsEventList from "../../resources/starlight/kegs/journey/event/KegsEventList";
import SummaryBarChart from "./SummaryBarChart";

const useStyles = makeStyles((theme) => ({

    detailsTableCellLeft: {
        width: '95px',
        fontSize: '1rem',
        fontWeight: '400'
    },
    detailsTableCellRight: {
        fontSize: '1rem',
        fontWeight: '400'
    },
    orientationImage: {
        width: '60px',
        filter: 'invert(100%)'
    }

}));

const Journey = ({}) => {
    const {cycleUuid} = useParams();
    const tableRef = React.createRef();

    const classes = useStyles();
    const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/summary?organisationUuid=${localStorage.getItem('oUuid')}`);
    const [summaryUrl, setSummaryUrl] = useState(baseUrl);
    const [summaryData, setSummaryData] = useState(null);
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [kegSummaryUrl, setKegSummaryUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/keg/${cycleUuid}/summary`);
    // const [kegUrl, setKegUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/keg-turn/${turnUuid}`);
    // const [kegUrl, setKegUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/keglatestinformations?kegUuid=${cycleUuid}`);
    const [kegJourneyUrl, setKegJourneyUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/kegs/${cycleUuid}/journey`);
    const [kegSummaryData, setKegSummaryData] = useState(null);
    const [kegData, setKegData] = useState(null);
    const [kegJourneyData, setKegJourneyData] = useState(null);
    const [eventsData, setEventsData] = useState(null);

    const [baseEventUrl, setBaseEventUrl] = useState(process.env.REACT_APP_KEGNOVA_API_URL+`/starlight/cycle/${cycleUuid}/timeline`);
    const [eventsUrl, setEventsUrl] = useState(baseEventUrl);

    let kegMovementProps = {
        basePath: `/starlight/kegs/journey/movement`,
        resource: `starlight/kegs/journey/movement`
    }
    let kegEventProps = {
        basePath: `/starlight/kegs/journey/event`,
        resource: `starlight/kegs/journey/event`
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(summaryUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data[0];
                setSummaryData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [summaryUrl]);

    const handleLocationFilterChange = (locationUuid) => {
        // console.log(locationUuid);
        if(locationUuid) {
            setSummaryUrl(baseUrl+'&locationUuid='+locationUuid);
            setLocation(locationUuid);
        } else {
            setSummaryUrl(baseUrl);
            setLocation(null);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(kegSummaryUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                setKegSummaryData(result.data);
                setKegData(result.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [kegSummaryUrl]);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             setLoading(true);
    //             const result = await axios.get(kegJourneyUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
    //             const data = result.data;
    //
    //             setLoading(false);
    //         } catch (error) {
    //             setLoading(false);
    //             console.log(error.response)
    //         }
    //     })();
    // }, [kegJourneyUrl]);

    // EVENTS TIMELINE API CALL
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const result = await axios.get(eventsUrl, { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache': 'no-cache', 'x-api-key': localStorage.getItem('apiKey')} });
                const data = result.data;
                setEventsData(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error.response)
            }
        })();
    }, [eventsUrl]);

    if ( !checkMystarStarlightAccess() ) {
        return (
            <UnauthorisedMystarStarlightAccess />
        )
    }

    const GetCurrentLocation = ({kegData}) => {
        return (
            <Paper square={true}>
                <Box m={2} textAlign={'center'} style={{height: '30px'}}><Typography variant={'h6'}>Last Location</Typography></Box>
                <Box m={2} textAlign={'center'} style={{height: '40px'}}>{ kegData.lastKnownLocations }</Box>
                <Box m={2}><Divider /></Box> <Box m={2} textAlign={'center'} style={{height: '70px'}}>{kegData.lastKnownLocationsAddress} </Box>
                <Box m={2} textAlign={'center'} style={{height: '30px'}}>{ new Date(kegData.lastKnownLocationsTime).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB') }</Box>
            </Paper>
        )
    };

    const TurnStatus = ({kegData}) => {
        return (
            <React.Fragment>
                <Box>
                    <Chip label={kegData.cycleStatus} color={'primary'} size={'small'}/>
                </Box>
                <Box>
                    <Typography variant={'caption'}>Started at {new Date(kegData.cycleStartDate).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB')}</Typography>
                </Box>
                { kegData && kegData.cycleStopAt &&
                    <Box><Typography variant={'caption'}>Ended at {new Date(kegData.cycleStopAt).toLocaleString((localStorage.getItem('cId') === 4) ? 'en-US' : 'en-GB')}</Typography></Box>
                }
            </React.Fragment>
        )
    }

    const getKegStatus = (kegData) => {

        let kegStatus = (kegData.kegIsFull !== 'N/A') ? kegData.kegIsFull : 'Not applicable';
        kegStatus = parseInt(kegData.kegIsFull) === 1 ? 'Full' : 'Empty';

        return kegStatus;
    }

    return (
        <React.Fragment>
            <Container style={{ backgroundColor: '#fff' }} >
                <Box m={1} style={{marginLeft: '1px'}} display={'flex'} >
                    <Box m={1} flexGrow={1}><Typography variant={'h5'}>Starlight Keg Cycle</Typography></Box>
                    <Box m={1}>
                        <Link href={ `/#/starlight/dashboard`}>
                            <Button variant={'outlined'}>View Keg Cycle Summary</Button>
                        </Link>
                    </Box>
                    <Box m={1} style={{marginRight: '2%'}}>
                        <Link href={ `/#/starlight/kegs/list`}>
                            <Button variant={'outlined'}>View Keg Cycle List</Button>
                        </Link>
                    </Box>
                </Box>
            </Container>

            <Box style={{backgroundColor: '#e7e6e7', width: '100vw', height: '100%'}}>
                <Container style={{ maxWidth: '1230px', marginTop: '16px' }} >
                    {/*<Box>*/}
                    {/*    <Summary summaryData={summaryData} loading={loading} handleLocationFilterChange={handleLocationFilterChange} />*/}
                    {/*</Box>*/}
                    <Box>
                        {/* Details and Starlight Insights */}
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Box m={1}>
                                    <Typography variant={'h5'}>Details</Typography>
                                </Box>
                                <Box m={1}>
                                    <Paper square={true}>
                                        { loading && <CircularProgress /> }
                                        { !kegData && <LinearProgress /> }
                                        {  kegData &&
                                        <Box display={'flex'}>
                                            <Box m={1}>
                                                <SkuTypeImage skuId={kegData.skuId ? kegData.skuId : '-'} />
                                                <Box justifyContent={'center'} alignContent={'center'} display={'flex'} flexDirection={'row-reverse'}>
                                                    <Box>{ (parseInt(kegData.readyForCollection) === 1) && <Chip avatar={<CheckCircleIcon style={{color: '#90be51'}}/>} label={'Ready for Collection'} color={'primary'} /> }</Box>
                                                </Box>
                                            </Box>
                                            <Box m={2} flexGrow={1}>
                                                <TableContainer>
                                                    <Table aria-label="keg details table" size="small">
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.detailsTableCellLeft}>Type</TableCell>
                                                                <TableCell align="right" className={classes.detailsTableCellRight}>{kegData.skuName ? kegData.skuName : '-' }</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.detailsTableCellLeft}>Serial Number</TableCell>
                                                                <TableCell align="right" className={classes.detailsTableCellRight}>{kegData.kegSerialNumber}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.detailsTableCellLeft}>Device ID</TableCell>
                                                                <TableCell align="right" className={classes.detailsTableCellRight}>{ kegData.deviceId ? kegData.deviceId : '-'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.detailsTableCellLeft}>Keg Status</TableCell>
                                                                <TableCell align="right" className={classes.detailsTableCellRight}>{ getKegStatus(kegData) } </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.detailsTableCellLeft}>Location</TableCell>
                                                                <TableCell align="right" className={classes.detailsTableCellRight}>
                                                                    { kegData.lastKnownLocations
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.detailsTableCellLeft}>Cycle Status</TableCell>
                                                                <TableCell align="right" className={classes.detailsTableCellRight}>
                                                                    <TurnStatus kegData={kegData} />
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Box>
                                        }
                                    </Paper>
                                </Box>
                                {/*<Box m={3}>*/}
                                {/*    <Divider />*/}
                                {/*</Box>*/}
                                <Box m={1} >
                                    <Grid container spacing={1}>
                                        <Grid item xs>
                                            { loading && !kegData && <LinearProgress /> }
                                            { kegData && kegData.lastKnownLocations && <GetCurrentLocation kegData={kegData} /> }
                                        </Grid>
                                        <Grid item xs>
                                            { loading && <LinearProgress /> }
                                            {  kegData &&
                                            <Paper square={true}>
                                                { kegData.currentTemp &&
                                                    <>
                                                        <Box m={2} textAlign={'center'} style={{height: '25px'}}><Typography variant={'h6'}>Last Temperature</Typography></Box>
                                                        <Box m={2} textAlign={'center'} style={{
                                                            height: '25px',
                                                            fontSize: 'x-large'
                                                        }}> <span>{kegData.currentTemp} &deg;C</span> </Box>
                                                    </>
                                                }
                                                { kegData.currentOrientation &&
                                                    <>
                                                        <Box m={2} textAlign={'center'} style={{height: '25px'}}><Typography variant={'h6'}>Current Orientation</Typography></Box>
                                                        <Box m={2} textAlign={'center'} style={{height: '95px'}}>
                                                            <Box style={{fontSize: 'large'}}>
                                                                <span>{kegData.currentOrientation} &deg;</span>
                                                            </Box>
                                                            <Box m={1}>
                                                                <img src={OrientationIcon}
                                                                     className={classes.orientationImage}
                                                                     style={{transform: `rotate(${ kegData.currentOrientation }deg)`}}
                                                                     alt={`Keg's Orientation`}/>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                }
                                            </Paper>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs>
                                {/*<Box m={1}>*/}
                                {/*    <SummaryBarChart />*/}
                                {/*</Box>*/}
                                <Box m={1}>
                                    <Typography variant={'h5'}>Keg Journey Timeline</Typography>
                                </Box>
                                <Box m={1}>
                                    <JourneyTimelineChart cycleUuid={cycleUuid} />
                                </Box>
                            </Grid>
                        </Grid>
                        {/* Details and Starlight Insights */}

                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Box m={1}>
                                    <KegJourneyMap cycleUuid={cycleUuid} />
                                </Box>

                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs>
                                <KegsMovementList {...kegMovementProps} cycleUuid={cycleUuid} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs>
                                { !eventsData && <LinearProgress /> }
                                {/*{ eventsData && <SummaryBarChart data={eventsData}/> }*/}
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs>
                                <KegsEventList  {...kegEventProps} cycleUuid={cycleUuid} />
                            </Grid>
                        </Grid>


                    </Box>

                </Container>
            </Box>
        </React.Fragment>
    )
}


export default Journey;
