// @ts-nocheck
import * as React from 'react';

import {
    Box, CircularProgress,

    Container,

    Typography,
}
    from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InvoiceList from "../../resources/invoices/InvoiceList";
import {useLoading} from "react-admin";

const Invoices = () => {
    const loading = useLoading();
    let invoicesProps = {
        basePath: "/invoices",
        resource: "invoices"
    }


    return (
        <React.Fragment>

            <Container style={{ maxWidth: '1230px'}} >
                { loading &&
                <Box>
                    <CircularProgress size={20} /> Please wait...
                </Box>
                }
                <Box m={1} style={{marginBottom: '25px'}}>
                    <Typography variant={'h4'}>Invoices </Typography>
                </Box>
            </Container>

            <Container style={{ maxWidth: '1230px' }} >
                    <InvoiceList {...invoicesProps} />
            </Container>
        </React.Fragment>
    );
};

export default Invoices;
