import React, {Fragment} from "react";
import styled, { keyframes } from 'styled-components';
import {rotateIn, zoomIn} from 'react-animations';
import {makeStyles} from "@material-ui/core/styles";
import circle from "../../assets/images/Circle.svg";
import logo from "../../assets/logos/KegstarLogo_Star.svg";

const loadingAnimationRotateIn = keyframes`${rotateIn}`;
const LoadingWrapperRotate = styled.div`
  animation: 1s ${loadingAnimationRotateIn} infinite;
`;
const loadingAnimationZoomIn = keyframes`${zoomIn}`;
const LoadingWrapperZoom = styled.div`
  animation: 0.75s ${loadingAnimationZoomIn} infinite;
`;
const useStyles = makeStyles( theme => ({
        circle: {
            width: '47px', opacity: 0.1,
            filter: 'invert(0)'
        },
        star: {
            width: '30px',
            filter: 'invert(0)'
        },
    })
);
const KegnovaDataLoader = () => {
    const classes = useStyles();

    return (
        <div style={{padding: '10px'}}>
            <div style={{
                position: 'absolute', marginLeft: '-9px', marginTop: '-8px'
            }}>
                <LoadingWrapperZoom>
                    <img src={circle} alt={''} className={classes.circle} />
                </LoadingWrapperZoom>
            </div>
            <div style={{position: 'absolute'}}>
                <LoadingWrapperRotate>
                    <img src={logo} alt={''} className={classes.star} />
                </LoadingWrapperRotate>
            </div>
        </div>
    )
}

export default KegnovaDataLoader;
